import { Card, Text, Image } from '@theme-ui/components'
import GatsbyLink from 'gatsby-link'
import React from 'react'

interface DestinationCardProps {
  src: string
  label: string
  slug: string
}

const DestinationCard: React.FC<DestinationCardProps> = ({ src, label, slug }) => {
  return (
    <GatsbyLink to={`/${slug}`}>
      <Card
        sx={{
          display: 'flex',
          position: 'relative',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          ':hover > img': {
            transform: 'scale(1.04)'
          },
          ':after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage:
              'linear-gradient(rgba(255,255,255,0), rgba(0,0,0,0.3))'
          }
        }}
      >
        <Image
          src={src}
          sx={{
            objectFit: 'cover',
            transition: 'transform .5s ease',
            aspectRatio: '1/1',
            width: '100%'
          }}
        />
        <Text
          sx={{
            position: 'absolute',
            padding: '1rem',
            color: 'white',
            fontSize: 'large',
            fontWeight: 600,
            zIndex: 2,
            textTransform: 'uppercase'
          }}
        >
          {label}
        </Text>
      </Card>
    </GatsbyLink>
  )
}
export default DestinationCard
