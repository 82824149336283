import React from 'react'
import { Flex, Link } from '@theme-ui/components'
import { Link as GatsbyLink } from 'gatsby'
import { useColorMode } from 'theme-ui'

import { useResponsiveValue } from '@theme-ui/match-media'
interface Element {
  name: String
  slug: String
  isActive: Boolean
}
interface HorizontalMenuProps {
  elements: Element[]
}

const HorizontalMenu: React.FC<HorizontalMenuProps> = ({ elements }) => {
  if (!elements) {
    return
  }
  const [colorMode] = useColorMode()
  const sx = getStyles(colorMode === 'dark')
  const isMobile = useResponsiveValue(
    [true, true, true, true, true, true, false],
    {
      defaultIndex: 7
    }
  )
  return (
    <Flex
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: isMobile ? 'normal' : 'center',
        padding: '1.5rem 0 1.5rem 0',
        marginBottom: '3rem',
        marginTop: '3rem',
        flexDirection: 'row',
        overflow: 'auto',
        scrollbarWidth: 'none',
        whiteSpace: 'nowrap',
        '::-webkit-scrollbar': {
          width: '0',
          display: 'none'
        }
      }}
    >
      {elements.map((element) =>
        element.isActive
          ? (
            <GatsbyLink
              to={`/${element.slug}`}
              style={sx.link}
              activeStyle={sx.active}
            >
              {element.name}
            </GatsbyLink>
            )
          : (
            <Link
              sx={{
                color: 'grey',
                minWidth: 'auto',
                fontWeight: 500,
                marginLeft: '0.5rem',
                padding: '0.5rem',
                borderRadius: '8px',
                textDecoration: 'none',
                whiteSpace: 'nowrap',
                cursor: 'default'
              }}
            >
              {element.name}
            </Link>
            )
      )}
    </Flex>
  )
}

export default HorizontalMenu

const getStyles = (isDark): void => ({
  link: {
    color: isDark ? 'white' : 'black',
    fontWeight: 500,
    marginLeft: '0.5rem',
    padding: '0.5rem',
    borderRadius: '8px',
    cursor: 'pointer',
    textDecoration: 'none',
    whiteSpace: 'nowrap'
  },
  active: {
    backgroundColor: isDark ? 'rgba(213,212,234,0.15)' : 'rgba(13,12,34,0.05)',
    fontWeight: 600
  }
})
