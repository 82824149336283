import React from 'react'

import Section from '@components/Section'
import SEO from '@components/SEO'
import Layout from '@components/Layout'

import { Template } from '@types'
import HorizontalMenu from '../../components/HorizontalMenu'
import { Box, Image } from '@theme-ui/components'

const StaticHeader: Template = ({ children, location, pageContext }) => {
  const menuItem = { name: 'All', slug: 'destinations', isActive: true }
  const continents = [menuItem, ...pageContext.continents]

  return (
    <Layout>
      <SEO pathname={location.pathname} />
      <Section>
        <Image
          src='/destination-hero.jpg'
          sx={{
            width: '100%',
            height: '20rem',
            objectFit: 'cover',
            marginTop: '5rem',
            borderRadius: '8px'
          }}
        />
        <HorizontalMenu elements={continents} />
        {children}
        {/* spacer for continent navigation not to jump  */}
        <Box sx={{ paddingBottom: '25rem' }} />
      </Section>
    </Layout>
  )
}

export default StaticHeader
