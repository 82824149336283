import React from 'react'
import { Grid } from 'theme-ui'

import DestinationCard from '../components/DestinationCard/DestinationCard'
import StaticHeader from '../components/StaticHeader'

const Destinations = ({ pageContext }): JSX.Element => {
  const countries = pageContext.countries
  return (
    <div>
      <Grid
        sx={{
          height: '100%',
          width: '100%',
          zIndex: '0',
          margin: 'auto'
        }}
        columns={[1, 1, 1, 1, 1, 4]}
        gap={['1rem']}
      >
        {countries.map((country, key) => (
          <DestinationCard
            key={key}
            label={country.label}
            src={country.image.medium.src}
            slug={`destinations/${country.slug}`}
          />
        ))}
      </Grid>
    </div>
  )
}

Destinations.Layout = StaticHeader

export default Destinations
